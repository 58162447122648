<template>
  <div>
    <NoticiasImporta />
  </div>
</template>

<script>
import NoticiasImporta from "@/components/noticias/NoticiasImporta.vue";

export default {
  components: {
    NoticiasImporta
  }
};
</script>
